@tailwind base;
@tailwind components;
@tailwind utilities;

body, html, #__next {
  font-family: Gotham, Arial, Sans-serif;
  height:100%;
  width: 100%;
  min-height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.32368;
}
  
  body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0 !important;
    height: 0 !important;
  }
  

a {
    text-decoration: none;
    color: inherit;
}


* {
    -webkit-tap-highlight-color: transparent;
  }
  
  a.not-purple {
    color: black;
  }
  
  a:visited.not-purple {
    color: black;
  }
  
  @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-thinitalic-webfont.woff2') format('woff2'),
         url('/fonts/gotham-thinitalic-webfont.woff') format('woff');
    font-weight: 100;
    font-style: italic;
  
  }
  
  @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-thin-webfont.woff2') format('woff2'),
         url('/fonts/gotham-thin-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
  
  }
  
  
  @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-xlightitalic-webfont.woff2') format('woff2'),
         url('/fonts/gotham-xlightitalic-webfont.woff') format('woff');
    font-weight: 200;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-xlight-webfont.woff2') format('woff2'),
         url('/fonts/gotham-xlight-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-lightitalic-webfont.woff2') format('woff2'),
         url('/fonts/gotham-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-light-webfont.woff2') format('woff2'),
         url('/fonts/gotham-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-bookitalic-webfont.woff2') format('woff2'),
         url('/fonts/gotham-bookitalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-book-webfont.woff2') format('woff2'),
         url('/fonts/gotham-book-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-mediumitalic-webfont.woff2') format('woff2'),
         url('/fonts/gotham-mediumitalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-medium-webfont.woff2') format('woff2'),
         url('/fonts/gotham-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-bolditalic-webfont.woff2') format('woff2'),
         url('/fonts/gotham-bolditalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-bold-webfont.woff2') format('woff2'),
         url('/fonts/gotham-bold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-blackitalic-webfont.woff2') format('woff2'),
         url('/fonts/gotham-blackitalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-black-webfont.woff2') format('woff2'),
         url('/fonts/gotham-black-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-ultraitalic-webfont.woff2') format('woff2'),
         url('/fonts/gotham-ultraitalic-webfont.woff') format('woff');
    font-weight: 800;
    font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Gotham';
    src: url('/fonts/gotham-ultra-webfont.woff2') format('woff2'),
         url('/fonts/gotham-ultra-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
  }
  
  
  /* 
  
    Gotham:
  
    thin 100
    extra light 200
    light 300
    book 400
    medium 500
    bold 600
    black 700
    ultra 800 
  
  */
  
  
  /* @font-face {
    font-family: 'Casablanca';
    src:  url('/fonts/urwcasablancabold-webfont.woff2') format('woff2'),
          url('/fonts/urwcasablancabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  } */
  
  /* @font-face {
    font-family: 'Phosphate';
    src: url('/fonts/phosphatesolid-webfont.woff2') format('woff2'),
          url('/fonts/phosphatesolid-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
   */
  /* @font-face {
    font-family: 'SignPainterSemibold';
    src: url('/fonts/fontsfree-net-signpainter-housescript-semibold-webfont.woff2') format('woff2'),
          url('/fonts/fontsfree-net-signpainter-housescript-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  } */
  
  
  .hide-scroll-bar {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
  
  
  .hide-scroll-bar::-webkit-scrollbar {
    display: none;
  }
  
  .modal .no-padding {
    padding: 0px !important;
  }
  
  .no-padding {
    padding: 0px !important;
  }
  
  .user-sidebar {
    padding: 0 !important;
    width: 270px !important;
  }
  
  button:focus {
    outline: 0;
  }
  
  * {
    box-sizing: border-box;
  }
  
  
  .visibility-hidden {
    visibility: hidden;
  }
  
  h1,
  h2 {
    font-family: Gotham, Arial, Sans-serif;
    font-weight: 400;
  }
  
  h1 {
    font-weight: 700;
  }
  
  h2 {
    font-weight: 600;
    font-size: 15px;
  }
  
  h3 {
    font-size: 12px;
  }
  
  .drawer.drawer-bottom {
    z-index: -1;
  }
  
  .drawer.drawer-bottom.drawer-open {
    z-index: 999 !important;
  }
  
  .drawer-content {
    overflow: hidden !important;
  }
  
  .menu-basket-slideover .drawer-content-wrapper {
    border-top-left-radius: 5px;
    overflow: hidden;
    border-top-right-radius: 5px;
    border-top: 1px solid #e2eaec;
  }
  
  .fadeIn {
    animation: fadeIn ease 3s;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  }

  .pushable>.pusher {
    height: 100% !important;
    overflow-y: scroll !important;
  }
  
  .ui.segment {
    border-radius: 0 !important;
    border: none !important;
  }
  
  .pushable {
    overflow-y: hidden;
  }
  
  .pushable>.pusher:after {
    position: fixed !important;
  }
  
  .ui.segment {
    margin: 0 !important;
  }